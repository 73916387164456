import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"

export const defaultTexts = {
    // Add all shared global texts
    ...sharedGlobalTexts,

    ebsTitle: "Espace bénéficiaire",
    menuCommandesTitle: "Commandes",

    // HEADER
    headerLogout: "Se déconnecter",
    headerMyProfile: "Mon profil",
    headerHelp: "Aide",
    headerChangeSpace: "Changer d'espace",

    // HOME
    homeHello: "Bonjour ",
    homeBienvenue: "Bienvenue",
    homeParagraph: "Bienvenue sur votre espace personnel Domiserve",
    homeCompteurList: "Vos compteurs",
    homeCommandes: "Vos commandes",
    homeCommandesSeeAll: "Voir toutes vos commandes ",
    homeCommandesIWantToOrder: "Je veux commander",
    homeCommandesSeeAll: "Voir tout",

    // HOME COMPTEURS
    homeCompteurTitle: "Compteur",
    homeCompteurExpiration: "valable jusqu'au",
    homeCompteurDisponible: "Disponible prévisionnel",
    homeCompteurTotal: total => `Sur ${total} au total`,

    // NOT FOUND
    backToHomeText: "Retour à l'accueil",

    // FAQ
    faqTitle: "La foire aux questions",

    // LOGIN
    loginParagraph: "Vous avez déjà un compte ?",
    loginParagraph2: "Identifiez-vous",
    loginParagraph3: "Pas encore de compte ?",
    loginParagraph4: "Inscrivez-vous",
    loginPlaceholder: "Email/Identifiant",
    loginButton: "Se connecter",
    loginForgottenLogin: "Identifiant oublié ?",
    loginForgottenPassword: "Mot de passe oublié ?",

    registrationSeparator: "OU",
    registrationButton: "S'inscrire",
    registrationContact: "Pour toute information, ",
    registrationContact2: "contactez-nous.",

    // FORGOTTEN PASSWORD
    forgottenPasswordParagraph: "Veuillez saisir votre adresse mail ci-dessous.",
    forgottenPasswordParagraph2: "Nous vous enverrons les instructions pour créer votre nouveau mot de passe.",
    forgottenPasswordMail: "Votre adresse mail",
    forgottenPasswordButton: "Je réinitialise mon mot de passe",
    forgottenPasswordConfirmation:
        "Vous allez recevoir un mail dans la boite de réception de votre adresse mail de connexion dans quelques minutes.",

    // FORGOTTEN LOGIN
    forgottenLoginParagraph: "Veuillez saisir votre numéro de téléphone fixe ci-dessus.",
    forgottenLoginParagraph2: "Nous vous enverrons les instructions pour récupérer votre identifiant.",
    forgottenLoginField: "Votre numéro de téléphone fixe",
    forgottenLoginButton: "Je récupère mon identifiant",
    forgottenLoginConfirmation:
        "Vous allez recevoir un mail dans la boîte de réception de votre adresse mail de connexion dans quelques minutes. Attention, vous ne pouvez réaliser cette opération que toutes les 6 heures",

    // COMMANDE
    commandeCancelCreationButton: "Annuler la saisie",
    commandePrecedantStep: "Etape précédente",
    commandeNextStep: "Etape suivante",

    commandeCancelCreationModalTitle: "Annuler la saisie",
    commandeCancelCreationModalParagraphe1:
        "Votre commande n'est pas encore créée. Toute les informations déjà saisies seront perdues.",
    commandeCancelCreationModalParagraphe2: "Etes-vous sûr(e) de vouloir abandonner votre saisie ?",
    commandeCancelCreationModalConfirmButton: "Oui, j'annule",
    commandeCancelCreationModalCancelButton: "Non, je continue",

    commandeConfirmCreationModalTitle: "Confirmation de la commande",
    commandeConfirmCreationModalParagraphe1: "Etes-vous sûr(e) de vouloir créer la commande ?",
    commandeConfirmCreationModalConfirmButton: "Oui, je crée la commande",
    commandeConfirmCreationModalCancelButton: "Non, je modifie",

    commandeDispositifChoiceSuiviButton: "Suivi des commandes",
    commandeDispositifBackToDispositifsLabel: "Retour aux dispositifs",
    commandeDispositifChoiceTitle: "Création de commandes",
    commandeDispositifChoiceSubtitle: "Choix du dispositif",
    commandeDispositifTypePrestaChoiceSubtitle: "Choix du type de prestation",
    commandeDispositifChoiceNoDispositif: "Aucun dispositif éligible.",
    commandeDispositifChoice2205Description:
        "Le dispositif Aide à domicile momentanée est un service d’aide à domicile proposé par l’Agirc-Arrco aux retraités de 75 ans et plus confrontés à une difficulté passagère (handicap temporaire, maladie, retour d’hospitalisation, absence de l’aidant habituel…) et ne bénéficiant pas de l’APA (allocation personnalisée d’autonomie). Cette aide peut prendre la forme d’une aide au ménage, aide aux courses ou à la préparation de repas.",
    commandeDispositifChoice10140Description:
        "Le diagnostic Bien chez moi, service proposé par l’AGIRC-ARRCO, accessible dès 75 ans, permet, avec l’aide d’un ergothérapeute (professionnel de santé diplômé d’Etat) d’examiner votre logement en détail afin de repérer d’éventuels facteurs de risques d’accidents domestiques. En fonction de cet état des lieux, mais aussi de vos habitudes de vie et de vos éventuelles contraintes (médicales, budgétaires, etc.), il vous proposera un ensemble de conseils et de solutions pour rendre votre logement plus sûr et confortable.",
    commandeDispositifChoice666Description:
        "Le dispositif Sortir Plus est un service Agirc-Arrco permettant aux retraités de 75 ans et plus de se faire accompagner, à pied ou en voiture, par une personne de confiance pour des déplacements de proximité : rendez-vous médicaux, courses, coiffeur, clubs de loisirs, visites dans la famille ou chez des amis…",
    commandeDispositifChoiceInformationsCompteur: value =>
        `Vous disposez de ${value} dans votre enveloppe utilisable jusqu'à la fin de l'année.`,
    commandeDispositifChoiceInformationsCompteurWithDate: (value, expirationDate) =>
        `Vous disposez de ${value} dans votre enveloppe utilisable jusqu'au ${expirationDate}.`,
    commandeDispositifInfosComplementairesTitle: "Informations complémentaires",
    commandeDispositifInfosComplementairesAutresInfos: "Autres informations",

    commandeDispositifInfosDispositif: label => `Dispositif : ${label}`,

    commandeAttributionPrestationType: "Type de prestation",
    commandeAttributionCommandeOrigin: "Comment avez vous entendu parler de ce dispositif ?",
    commandeAttributionPrestationDate: "Date de début de prestation souhaitée",
    commandeAttributionComment: "Commentaire à destination du prestataire",
    commandeAttributionAttributionTitle: "Questionnaire d'attribution",
    commandeAttributionDetails: "Détails de la prestation",
    commandeAttribution2205Questions: [
        {
            fieldName: "question0",
            text: "Vous avez déjà bénéficié de l'aide à domicile momentanée. Rencontrez-vous les mêmes difficultés que lors de votre précédente commande de prestation ?",
        },
        {
            fieldName: "question1",
            text: "1.Bénéficiez-vous d'un plan d'aide pérenne (APA, PCH...) ?",
        },
        {
            fieldName: "question2",

            text: "2.MALADIE : Rencontrez-vous un problème de santé passager (état grippal, rhume, fatigue...) ?",
        },
        {
            fieldName: "question3",

            text: "3.HANDICAP TEMPORAIRE : Êtes-vous en situation de handicap temporaire (suite à une foulure, cassure...) ?",
        },
        {
            fieldName: "question4",

            text: "4.ABSENCE DE L'AIDANT HABITUEL : Les proches qui vous aident habituellement sont-ils absents ? ",
        },
        {fieldName: "question5", text: "5.HOSPITALISATION : Sortez-vous d'une hospitalisation ? "},
        {
            fieldName: "question6",
            text: "6.Bénéficiez-vous d'une aide de votre CARSAT/ CNAV (PAP ou ARDH) ?",
        },
    ],
    commandeAttributionNextButton: "Passer à l'étape suivante",
    commandeAttribution10140Questions: [
        {fieldName: "alreadyBCM", text: "Un membre de votre foyer a t-il déjà mobilisé Bien Chez Moi ?"},
        {fieldName: "question1", text: "Rencontrez-vous des difficultés à vous déplacer dans votre domicile ?"},
        {fieldName: "question2", text: "Rencontrez-vous une situation d'handicap ?"},
        {fieldName: "question3", text: "Avez-vous été confronté(e) à des accidents répétés dans votre domicile ?"},
        {fieldName: "question4", text: "Etes-vous inquiet(e) de rester à votre domicile ?"},
        {fieldName: "question5", text: "Est-ce en mesure de prévention des risques à domicile ?"},
        {
            fieldName: "question6",
            text: "Faites-vous appel au diagnostic suite aux recommandations d'un proche ou d'un professionnel ?",
        },
        {fieldName: "question7", text: "Faites-vous appel au diagnostic dans le cadre du pack antichute ?"},
    ],
    commandeCreateCommandeButton: "Valider",

    commandeZoneInterventionTitle: "Choix du prestataire",

    commandePrestationTitle: "PRESTATION",
    commandePrestationNbHours: "Nombre d'heures souhaitées",
    commandePrestationPlaces: "LIEUX",
    commandePrestationPlaceTitle: "Lieu de la prestation",
    commandePrestationPickupPrestationPlace: "Lieu de prise en charge",
    commandePrestationPickupPrestation: "Prise en charge",
    commandePrestationRecipient: "Destinataire",
    commandePrestationAddressSearching: "Recherche d'adresse",
    commandePrestationAddress2: "Numéro et nom de voie",
    commandePrestationAddress1: "Complément d'adresse",
    commandePrestationAddress3: "Lieu-dit",
    commandePrestationPostalCodeTown: "Code postal, Ville",

    commandePrestationDetails: "Détails de la prestation",

    commandePrestationPrestationType: "Type de prestation",
    commandePrestationPrestationDate: "Date de prestation",
    commandePrestationPrestationDatePopup:
        "La date sélectionnée doit être postérieure à 48heures ouvrées afin de pouvoir traiter votre commande.",
    commandePrestationPrestationAddDate: "Ajouter une date de prestation",
    commandePrestationPrestationDeleteDate: "Supprimer",

    commandePrestationHours: "HORAIRES",
    commandePrestationHourStartTitle: "Aller",
    commandePrestationHourReturnTitle: "Retour",
    commandePrestationDestinationTitle: "Lieu de destination",
    commandePrestationDestination: "Destination",
    commandePrestationInformationTitle: "Information prestataire",

    commandePrestationUsageProject: "Projet d'utilisation",
    commandePrestationOutingReason: "Motif de la sortie",
    commandePrestationStartHour: "Heure de départ",
    commandePrestationPickUpHour: "Heure de prise en charge",
    commandePrestationEndHour: "Heure de fin de prestation",
    commandePrestationInChargeEndHour: "Heure d'arrivée",
    commandePrestationHourError: "Veuillez saisir une heure de retour postérieure à l’heure de départ",
    commandePrestationDuration: "Durée prévisionnelle",
    commandePrestationOutingPlace: "Lieu de sortie",
    commandePrestationShoppingPlace: "Lieu des courses",
    commandePrestationPath: "Trajet",
    commandePrestationPathReturn: "Aller/Retour",
    commandePrestationPathReturnAndWithWaitingWarning:
        "Attention, si vous souhaitez faire un aller retour sans attente, vous devez créer deux sorties en aller simple. La première pour votre aller et la seconde pour votre retour.",
    commandePrestationPathOutward: "Aller simple",
    commandePrestationWaiting: "Attente",
    commandePrestationWithWaiting: "Avec attente",
    commandePrestationWithoutWaiting: "Sans attente",
    commandePrestationSupport: "Serez-vous avec un accompagnant ?",
    commandePrestationPrestationNatureQuestion: "Quel type d'accompagnement souhaitez vous ?",
    commandePrestationPrestationNature: "Type d'accompagnement",
    commandePrestationDistance: "Distance globale à parcourir (km)",
    commandePrestationDistancePopup:
        "Cette information correspond au nombre total de kilomètres à parcourir pendant l'ensemble de la sortie (aller et retour, le cas échéant)",
    commandePrestationDistanceUpdate: "Calculer la distance à parcourir",
    commandePrestationDistanceError: "Veuillez saisir une distance à parcourir supérieure à 0 km.",
    commandePrestationWithoutVehicle: "Accompagnement non véhiculé",
    commandePrestationWithVehicle: "Accompagnement avec un véhicule",
    commandePrestationWithAdaptedVehicle: "Accompagnement avec un véhicule adapté",
    commandePrestationNbHours10: "10h00",

    commandePrestationPrestaEnParticulier: "Souhaitez-vous un prestataire en particulier ?",
    commandePrestationPrestaSouhaite: "Nom du prestataire",
    commandePrestationPrestaSouhaiteTel: "Numéro téléphone du prestataire",
    commandePrestationPrestaSouhaiteMail: "Email du prestataire",
    commandePrestationAccordMission: "Avez-vous l'accord du prestataire pour réaliser la mission ?",
    commandePrestationAutrePresta:
        "Si le prestataire ne peut pas assurer la mission, êtes-vous d'accord pour que nous en sélectionnions un autre ?",
    commandePrestationPrestationComment: "Commentaire à destination du prestataire",

    commandePrestationPreviousStepButton: "Revenir à l'étape précédente",

    commandePrestationNbHoursFieldErrorMin: hours =>
        `Le nombre d’heures global de la commande doit être d'un minimum de ${hours} heure(s). Veuillez modifier votre commande`,
    commandePrestationNbHoursFieldErrorMax: hours =>
        `Le nombre d’heures global de la commande doit être ${hours} heure(s) au maximum. Veuillez modifier votre commande`,
    commandePrestationNbHoursFieldErrorDifferentMinMax: (min, max) =>
        `Le nombre d’heures global de la commande doit être compris entre ${min} heure(s) et ${max} heure(s). Veuillez modifier votre commande`,
    commandePrestationNbHoursFieldErrorSliceNotSame: hours =>
        `Le nombre d’heures global de la commande doit être égal à ${hours} heure(s). Veuillez modifier votre commande`,

    commandePrestationNbHoursFieldErrorPerSliceMin: hours =>
        `La tranche horaire doit être supérieure ou égale à ${hours}h`,
    commandePrestationNbHoursFieldErrorPerSliceMax: hours =>
        `La tranche horaire doit être inférieure ou égale à ${hours}h`,
    commandePrestationNbHoursFieldErrorPerSliceMinMax: (min, max) =>
        `La tranche horaire doit être comprise entre ${min}h et ${max}h`,

    commandePrestationInformationsGlobalHoursMin:
        hours => `Les informations sur les dates et horaires sont renseignées à titre indicatif. Le planning d'intervention définitif sera à établir avec le prestataire lors de sa prise de contact avec vous. <br/>
    Les prestations sont facturées par tranches minimales de ${hours} heure(s)`,
    commandePrestationInformationsGlobalHoursMax:
        hours => `Les informations sur les dates et horaires sont renseignées à titre indicatif. Le planning d'intervention définitif sera à établir avec le prestataire lors de sa prise de contact avec vous. <br/>
    Vous pouvez commander un maximum de ${hours} heure(s) de prestation`,
    commandePrestationInformationsGlobalHoursDifferentMinMax: (
        min,
        max,
    ) => `Les informations sur les dates et horaires sont renseignées à titre indicatif. Le planning d'intervention définitif sera à établir avec le prestataire lors de sa prise de contact avec vous. <br/>
        Les prestations sont facturées par tranches minimales de ${min} heure(s). <br/>
        Vous pouvez commander un maximum de ${max} heure(s) de prestation`,
    commandePrestationInformationsGlobalHoursSameMinMax: `Les informations sur les dates et horaires sont renseignées à titre indicatif. Le planning d'intervention définitif sera à établir avec le prestataire lors de sa prise de contact avec vous.`,

    commandePrestationInformationsHourSlicesMin: hours =>
        `Les prestations sont facturées par tranches minimales de ${hours} heure(s).`,
    commandePrestationInformationsHourSlicesMax: hours =>
        `Vous pouvez commander un maximum de ${hours} heure(s) de prestation`,
    commandePrestationInformationsHourSlicesDifferentMinMax: (min, max) =>
        `Les prestations sont facturées par tranches minimales de ${min} heure(s). <br/> Vous pouvez commander un maximum de ${max} heure(s) de prestation`,
    commandePrestationInformationsHourSlicesSameMinMax: hours =>
        `Les prestations sont facturées par tranches minimales de ${hours} heure(s).`,

    commandePrestationInformationsCompteur: value => `Vous disposez de ${value} dans votre enveloppe.`,

    commandePrestationAmountTitle: "Montant prévisionnel",
    commandePrestationAmountTotalAmountTTC: `Montant total TTC prévisionnel`,
    commandePrestationAmountHourRateTTC: `Taux horaire TTC`,
    commandePrestationBeneficiairePartTTC: `Reste à votre charge TTC`,
    commandePrestationPriceTTC: `Prix total TTC (à votre charge)`,

    commandeInformationsPaiementModeDePaiement: "Sélection du mode de paiement",
    commandeInformationsPaiementModeDePaiementDescription:
        "Afin de mettre en place la prestation, vous devez sélectionner un moyen de paiement pour votre prestation.",
    commandeInformationsPaiementMandat: "Sélection du mandat",
    commandeInformationsPaiementMandatManquant:
        "Afin de mettre en place la prestation, vous devez créer un mandat en cliquant sur le lien ci-dessous",
    commandeInformationsPaiementMandatExistant:
        "Afin de mettre en place la prestation, le mandat ci-dessous sera sélectionné :",
    commandeInformationsPaiementAjouterMandat: "Ajouter mandat",
    commandeInformationsPaiementRemplacerMandat: "Remplacer mandat",
    commandeInformationsPaiementAvertissementMandatActif:
        " Attention, le remplacement du mandat concerne toutes les prestations pour lesquelles une facture n’a pas encore été envoyée",
    commandeInformationsPaiementMandatLabel: mandat =>
        `Compte de ${mandat.titulaireCompte} IBAN finissant par (${mandat.iban.substring(
            mandat.iban.length - 3,
            mandat.iban.length,
        )})`,
    commandeInformationsPaiementChequeActif: "Un chèque a bien été enregistré pour ce plan d'aide",

    commandeConfirmationParagraph1: (prestationType, serviceRequestNumber, date) =>
        `Nous avons bien enregistré votre commande de <b>${prestationType}</b> n° <b>${serviceRequestNumber}</b> le <b>${date}</b>. Vous recevrez un mail de confirmation d'ici quelques minutes.`,
    commandeConfirmationParagraph2: "Voici le récapitulatif :",
    commandeConfirmationPrestationRecapDispositif: (prestationType, contratLabel) =>
        `Prestation ${prestationType} enregistrée dans le cadre du dispositif ${contratLabel}`,
    commandeConfirmationPrestationRecapDate: prestationDate => `Intervention souhaitée à partir du ${prestationDate}`,
    commandeConfirmationPrestationDate: prestationDate => `le ${prestationDate}`,
    commandeConfirmationPathWaitingSupport: (path, waiting, support) =>
        `${path === "ALLER_RETOUR" ? "Aller-retour" : "Aller simple"}, ${
            waiting === 1 ? "avec attente" : "sans attente"
        }, ${support === 1 ? "avec un accompagnant" : "sans accompagnant"}`,
    commandeConfirmationParagraph3Accepted: phoneNumber =>
        `Pour tout besoin d'information complémentaire, n'hésitez pas à nous contacter au numéro ${phoneNumber} (Service gratuit + Prix d'appel).`,
    commandeConfirmationOutingPlace: `Lieu de sortie : `,
    commandeConfirmationShoppingPlace: `Lieu de courses : `,
    commandeConfirmationDistance: distance => `Distance à parcourir : ${distance}km`,

    commandeConfirmationRecap: (prestationLabel, prestationCreationDate) =>
        `Voici le récapitulatif de votre commande : <b>${prestationLabel}</b> demandée le <b>${prestationCreationDate}</b>.`,
    commandeConfirmationBeneficiaire: `Bénéficiaire : `,
    commandeConfirmationPrestationDateDesired: `Date de la prestation souhaitée : `,
    commandeConfirmationPrestationType: `Type de prestation : `,
    commandeConfirmationPrestationType2: ` dans le cadre du dispositif `,
    commandeConfirmationDistance2: `Distance estimée à parcourir : `,
    commandeConfirmationPath: `Trajet`,
    commandeConfirmationPath1: `Trajet Aller`,
    commandeConfirmationPath2: `Trajet Retour`,
    commandeConfirmationPrestation: `Prestation`,
    commandeConfirmationInChargeHour: `Heure de prise en charge : `,
    commandeConfirmationIntervention: `Intervention souhaitée à partir du `,
    commandeConfirmationInChargePlace: `Lieu de prise en charge : `,
    commandeConfirmationDestinationAddress: `Lieu de destination :  `,
    commandeConfirmationHome: `Adresse du domicile concerné : `,
    commandeConfirmationPathDuration: `Durée estimée du trajet : `,
    commandeConfirmationSelectedNbHours: `Nombre d'heures sélectionnées : `,
    commandeConfirmationNoPrestataire: `Vous n'avez pas renseigné de souhait de prestataire.`,
    commandeConfirmationModeDePaiement: `Vous avez sélectionné le mode de paiement: `,
    commandeConfirmationModeDePaiementPrelevement: (titulaireCompte, iban) =>
        `Le compte attribué au mandat actif est celui de ${titulaireCompte}, finissant par (${iban.substring(
            iban.length - 3,
            iban.length,
        )})`,
    commandeConfirmationPrestataireOnly: `Vous souhaitez faire uniquement appel au prestataire : `,
    commandeConfirmationPrestataire: `Vous souhaitez faire appel au prestataire : `,
    commandeConfirmationPrestataireAvailability: `Sous réserve de la disponibilité du prestataire à la date et heure souhaitée.`,
    commandeConfirmationHourlyRate: `Le tarif horaire pour une prestation de type `,
    commandeConfirmationHourlyRate2: `est de `,
    commandeConfirmationTotalAmount: `Montant estimé provisoire de la prestation :  `,
    commandeConfirmationTotalAmountBeneficiairePart: `Montant estimé provisoire restant à votre charge : `,
    commandeConfirmationCounter: (value, expirationDate) =>
        `Suite à cette prestation, il vous restera ${value} € dans votre enveloppe utilisable jusqu'au ${expirationDate}.`,
    commandeConfirmationCgu: `J'accepte les `,
    commandeConfirmationCguLink: `conditions générales de service`,
    commandeConfirmationCguError: `Veuillez accepter les conditions générales d'utilisation pour continuer`,
    commandeConfirmationParagraph4: "Nous restons à votre écoute.",
    commandeConfirmationParagraph5: "Bien cordialement",

    commandeConfirmationConfirmationInfos: "Je certifie l'exactitude des données renseignées",
    commandeConfirmationConfirmationInfosError: "Cette case doit être cochée afin de valider votre commande.",

    commandeConfirmationDeniedTitle: "ATTRIBUTION REFUSÉE",
    commandeConfirmationPendingTitle: "ATTRIBUTION EN ATTENTE",
    commandeConfirmationDeniedParagraph: commandeNumber =>
        `Nous ne pouvons malheureusement pas donner suite à votre commande de prestation n° ${commandeNumber}.`,

    commandeConfirmationFinish: "Terminer",

    commandeConfirmationNotification: numCommande =>
        `Votre commande de prestation n° ${numCommande} a été enregistrée, vous recevrez un mail récapitulatif d'ici quelques minutes.`,

    // AREA CHOICE
    areaChoiceTitle: "Connexion",
    areaChoiceParagraph: "Veuillez choisir l'espace auquel vous souhaitez accéder :",
    areaChoiceNoMoreAccesOnThisArea: (firstName, name) =>
        `Vous n'êtes plus autorisé à accéder à l'espace de ${firstName} ${name}`,

    // CLIENT CHOICE
    clientChoiceTitle: "Connexion",
    clientChoiceParagraph: "Veuillez choisir l'espace auquel vous souhaitez accéder :",

    // REGISTRATION PROFILE
    registrationProfileParagraph:
        "Merci de remplir les informations ci-dessous pour créer votre accès à votre espace personnel.",
    registrationProfileEmail: "Votre adresse mail",
    registrationBeneficiaireProfileParagraph:
        "Merci de remplir les informations ci-dessous pour créer votre espace personnel.",
    registrationProcheProfileParagraph:
        "Merci de remplir les informations ci-dessous pour créer votre accès à cet espace personnel.",
    registrationBeneficiaireProfileBirthDate: "Votre date de naissance",
    registrationProfileBirthDate: "La date de naissance du bénéficiaire",
    registrationProfileError: "Une ou plusieurs informations saisies sont incorrectes.",
    registrationProfileDateError: "Merci de saisir une date de naissance au format JJ/MM/AAAA",
    registrationTokenError: "Le lien n'est plus valable.",

    // REGISTRATION PASSWORD
    registrationPasswordParagraph: "Merci de créer votre mot de passe pour accéder à votre espace personnel.",
    registrationPasswordField: "Votre nouveau mot de passe",
    registrationPasswordConfirmationField: "Confirmation de votre mot de passe",
    registrationPasswordSuccess: "Inscription réalisée avec succès.",

    // RESET PASSWORD SCENE
    resetPasswordSuccessText: "Votre mot de passe a bien été changé. Vous allez être redirigé dans 5 secondes.",
    resetPasswordSubmitButtonLabel: "Je valide mon mot de passe",

    // ADDRESS LABELS
    address0Label: "Destinataire",
    address1Label: "Complément d'adresse",
    address2Label: "Adresse",
    address3Label: "Lieu-dit",
    postalCodeTown: "Code postal, ville",

    // CONTACT INFORMATION LABEL
    phoneNumberLabel: "Téléphone fixe",
    mobilePhoneNumberLabel: "Téléphone mobile",
    emailAddressLabel: "Email",

    // PROFILE
    profileTitle: "Mon compte",
    profileTitleEditedByRelative: name => `Profil de ${name}`,

    profileTabMainInfoTitle: "Informations principales",
    profileTabRelativesTitle: "Proches",
    profileTabComplementaryInfosTitle: "Informations complémentaires",
    profileTabConnectionInfosTitle: "Informations de connexion",
    profileTabEnvelopeTitle: "Enveloppe",
    profileTabModalTitle: "Enregistrer les modifications",
    profileTabModalParagraph:
        "Si vous changez d'onglet sans enregistrer vos modifications, ces dernières seront perdues.",
    profileTabModalParagraph2: "Souhaitez-vous enregistrer vos modifications ?",
    profileTabModalReturnButton: "Annuler",
    profileTabModalChangeButton: "Enregistrer",

    profileEbUniqueInfo:
        "Si vous souhaitez modifier votre profil, veuillez vous connecter à votre espace bénéficiaire en cliquant ",

    profileIdentityTitle: "Identité",
    profileIdentityCivility: "Civilité",
    profileIdentityUsageName: "Nom d'usage",
    profileIdentityRelativeName: "Nom",
    profileIdentityBirthName: "Nom de naissance",
    profileIdentityFirstName: "Prénom",
    profileIdentityBirthDate: "Date de naissance",

    profileAddressTitle: "Adresse",
    profileAddress2: "Numéro et nom de voie",
    profileAddress1: "Complément d'adresse",
    profileAddress3: "Lieu dit",
    profileAddressPostalCodeTown: "Code postal, Ville",

    profilePhoneNumberTitle: "Téléphones",
    profileHomePhoneNumber: "Téléphone fixe",
    profileMobilePhoneNumber: "Téléphone mobile",
    profileChangeEmailSegmentTitle: "Modifier mon adresse mail",
    profileChangeEmailSegmentDescription:
        "Saisissez votre nouvelle adresse mail dans le champ “Adresse mail” puis confirmez la nouvelle adresse dans le champ “Confirmation adresse mail” et cliquez sur le bouton Enregistrer.",
    profilePhoneNumberEmailEmailAddress: "Adresse mail",
    profilePhoneNumberEmailEmailConfirmation: "Confirmez votre adresse mail",
    profilePhoneNumberEmailConfirmationMode: "Mode de confirmation",
    profileChangePasswordSegmentTitle: "Modifier mon mot de passe",
    profileChangePasswordSegmentDescription:
        "Saisissez votre mot de passe actuel puis saisissez votre nouveau mot de passe dans les champs dédiés en respectant les règles de sécurité et cliquez sur le bouton Enregistrer.",
    profileCurrentPassword: "Mot de passe actuel",

    profileRelativesTitle: "Proches",
    profileRelativesTableName: "Prénom Nom",
    profileRelativesTableRole: "Rôle",
    profileRelativesTablePhone: "Téléphone(s)",
    profileRelativesTableEmail: "Email",
    profileRelativesTableAccess: "Autorisation accès Espace",
    profileRelativesTableAction: "Action",
    profileRelativesTableSee: "Voir",
    profileRelativesButton: "Ajouter un proche",

    profileRelativesDeleteSuccess: fullBeneficiaryName =>
        `Le proche ${fullBeneficiaryName} est définitivement supprimé.`,
    profileRelativesAddSuccess: "Le proche a été ajouté avec succès.",
    profileRelativesUpdateSuccess: "Le proche a été modifié avec succès.",

    profileRelativesName: "Nom",
    profileRelativeMainPhone: "Téléphone fixe",
    profileRelativeSecondaryPhone: "Téléphone mobile",
    profileRelativeEBSAccess: "Accès Espace Activé",
    profileRelativesAccessQuestion: "Autorisez-vous votre proche à accéder à votre espace personnel ?",
    profileRelativesContactInNeedQuestion: "Autorisez-vous ce proche à être contacté en cas de besoin ? ",
    profileRelativesNotifyPrestation:
        "Autorisez-vous ce proche à recevoir également les notifications de vos prestations ? ",

    profileRelativeModalTitle: "Proche",
    profileRelativeModalTitleNew: "Nouveau proche",
    profileRelativeModalDescription:
        "Cette personne aura également le droit de consulter et de gérer vos commandes par téléphone ou via votre espace si vous l'autorisez à y accéder. Dès votre validation il recevra un lien pour lui permettre d'accéder à votre espace.",
    profileRelativeModalMessage: "Message pour le proche",
    profileRelativeModalDeleteButton: "Supprimer le proche",
    profileRelativeModalEditRelativeButton: "Modifier le proche",
    profileRelativeModalCancelButton: "Annuler l'ajout",
    profileRelativeModalAddButton: "Ajouter le proche",
    profileRelativeModalCancelEditionButton: "Annuler les modifications",
    profileRelativeModalEditButton: "Enregistrer les modifications",
    profileRelativeModalMessageNbCarac: "/ 1000 caractère(s) autorisé(s).",

    profileRelativesDeletionModalTitle: "Suppression d'un proche",
    profileRelativesDeletionModalParagraph: relativeName =>
        `Vous êtes sur le point de supprimer votre proche ${relativeName} définitivement. Êtes vous sûr(e) de vouloir le supprimer ? `,
    profileRelativesDeletionModalYesButton: "Confirmer la suppression",
    profileRelativesDeletionModalNoButton: "Annuler la supression",

    profileModalEmailEditingParagraph:
        "Vous êtes sur le point de modifier votre adresse mail. L'adresse mail que vous utilisez lors de la connexion sera également modifiée.",
    profileModalEmailEditingParagraph2: "Êtes vous sûr(e) de vouloir modifier votre adresse mail ?",
    profileModalEmailEditingCancelButton: "Annuler les modifications",
    profileModalEmailEditingSaveButton: "Enregistrer les modifications",
    profileUpdateSuccess: "Vos modifications ont été enregistrées.",

    informationConnectionEmailAddress: "Adresse mail",
    informationConnectionConfirmationEmailAddress: "Confimer votre adresse mail",

    // COMMANDES
    commandePageTitle: "Suivi de commandes",
    commandeNewCommandeLabel: "Nouvelle commande",
    commandePasDeCommande: "Vous n'avez pas de commandes",
    commandeNumber: number => `Commande N°${number}`,
    commandePrestation: dispositif => `Prestation - ${dispositif}`,
    commandeFormPanelNumber: "Numéro",
    commandeFormPanelType: "Type de commande",
    commandeFormPanelDateCreation: "Date de création",
    commandeFormPanelDatePrestation: "Date de prestation souhaitée",
    commandeFormPanelDateFrom: "Du",
    commandeFormPanelDateTo: "Au",
    commandeFormPanelCreatedBy: "Créée par",
    commandeFormPanelStatus: "Statut",
    commandeFormPanelAction: "Action",
    commandeFormPanelCommandeNumber: "Numéro de commande",
    commandeFormPanelCommandeNumberPlaceholder: "DXXXXXXX",
    commandeFormPanelCommandeType: "Type de commande",
    commandeFormPanelCommandeContract: "Dispositif",
    commandeFormPanelCommandeStatus: "Statut",
    commandeFormPanelSearchButton: "Rechercher",
    commandeFormPanelExportButton: "Export Excel",
    commandeFormPanelActionSee: "Voir",
    commandeFormPanelActionSelect: "Sélectionner",

    commandeVisualisationModificationHeaderTitle: "Détail de la commande",
    commandeVisualisationModificationHeaderParagraph1: (numCommande, creationDate, fullName) =>
        `Commande de prestation ${numCommande}, créée le ${creationDate}, dans mon espace personnel, par ${fullName}.`,
    commandeVisualisationModificationHeaderParagraph1OtherArea: (numCommande, creationDate) =>
        `Commande de prestation ${numCommande}, créée le ${creationDate}, par téléphone.`,
    commandeVisualisationModificationDispositif: label => `Dispositif : ${label}`,

    commandeVisualisationModificationStatus: "Statut ",
    commandeVisualisationModificationAttribution: "Attribution",
    commandeVisualisationModificationAttributionPendingStatus: "Attribution en attente",
    commandeVisualisationModificationAttributionStatus: accepted =>
        "Attribution " + (accepted ? "acceptée" : "refusée"),
    commandeVisualisationModificationPrestation: "Prestation",
    commandeVisualisationModificationInformationsPrestataire: "Informations prestataire",
    commandeVisualisationModificationComplements: "Compléments de commande",
    commandeVisualisationModificationInChargePlace: "Lieu de prise en charge",
    commandeVisualisationModificationPrestationPlace: "Lieu de prestation",
    commandeVisualisationModificationFacture: "Facture",
    commandeVisualisationModificationFactureStatut: "Statut",
    commandeVisualisationModificationFactureStatutDisponible: "Disponible",
    commandeVisualisationModificationFactureStatutNonDisponible: "Pas encore disponible",
    commandeVisualisationModificationFactureDate: "Date de facturation",
    commandeVisualisationModificationFactureTelechargement: "Téléchargement",
    commandeVisualisationModificationReturnButton: "Retour aux commandes",
    commandeVisualisationModificationEditButton: "Modifier la commande",
    commandeVisualisationModificationCancelEditionButton: "Annuler les modifications",
    commandeVisualisationModificationSaveButton: "Enregistrer les modifications",
    commandeVisualisationModificationCancelButton: "Annuler la commande",

    commandeVisualisationModificationPreviewHeaderTitle: numCommande => `Commande ${numCommande}`,
    commandeVisualisationModificationPreviewHeaderParagraph1: prestation => `Prestation - ${prestation}`,
    commandeVisualisationModificationPreviewHeaderPrestationDateFrom: "Date de début de prestation souhaitée",
    commandeVisualisationModificationPreviewHeaderPrestationDate: "Date de prestation souhaitée",
    commandeVisualisationModificationPreviewNbHoursDesired: "Nombre d'heures demandées",
    commandeVisualisationModificationPreviewAddress: "Adresse",
    commandeVisualisationModificationPreviewAddressFrom: "Chez ",

    commandeVisualisationModificationSuccess: "Modifications enregistrées avec succès.",

    commandeVisualisationModificationPreviewModalTitle: "Commande",
    commandeVisualisationModificationPreviewModalParagraph1:
        "Si vous quittez cette page sans enregistrer, les modifications seront perdues.",
    commandeVisualisationModificationPreviewModalParagraph2: "Êtes-vous sûr(e) de vouloir quitter cette page ?",
    commandeVisualisationModificationPreviewModalCancelButton: "Non, je reste sur cette page",
    commandeVisualisationModificationPreviewModalAcceptButton: "Oui, je quitte cette page",

    commandeVisualisationModificationCancellationTitle: "Annulation de commande",
    commandeVisualisationModificationCancellationParagraph1:
        "Vous êtes sur le point d'annuler définitivement la commande ",
    commandeVisualisationModificationCancellationParagraph2: "Êtes vous sûr(e) de vouloir annuler la commande ?",
    commandeVisualisationModificationCancellationNoButton: "Non, je n'annule pas la commande",
    commandeVisualisationModificationCancellationYesButton: "Oui, j'annule la commande",

    commandeVisualisationModificationCancellationRequestTitle: "Demande d'annulation de commande",
    commandeVisualisationModificationCancellationRequestParagraph1: numCommande =>
        `Votre commande ${numCommande} est en cours de traitement par nos services. Si vous confirmez la demande d'annulation, nos services vont étudier votre commande et vous recontacter (par mail ou par téléphone).`,
    commandeVisualisationModificationCancellationRequestParagraph2: "Souhaitez-vous envoyer une demande d'annulation ?",
    commandeVisualisationModificationCancellationRequestNoButton: "Non, je n'envoie pas la demande d'annulation",
    commandeVisualisationModificationCancellationRequestYesButton: "Oui, j'envoie la demande d'annulation",

    commandeVisualisationModificationCancellationWarning: "Une demande d’annulation est en cours de traitement.",

    // PAGE PREVIEW :
    pagePreviewClose: "Fermer",
    pagePreviewExtend: "Voir tout",

    // PLACEHOLDER
    placeholderEmail: "xxx@abc.xxx",

    // VALIDATION
    validationEmail: "L’adresse mail doit être au format xxx@abc.zz ou xxx@abc.com",
    infoRequiredFields: "Les champs munis d'une étoile * sont obligatoires",
    infoRequiredTelephoneFields: "** Au moins un des deux champs de téléphone est obligatoire",

    // CONTACT
    contactTitle: "Contact",

    contactInformationsTitle: "Nous appeler",
    contactInformationsParagraphe1:
        "Vous avez besoin d’informations et vous n’avez pas trouvé de réponse à vos questions dans notre",
    contactInformationsLink1: "Foire Aux Questions",
    contactInformationsParagraphe2: "Vous pouvez nous contacter au",
    contactInformationsParagraphe3: "(Service gratuit + Prix d'appel).",
    contactInformationsParagraphe4: (heureMin, heureMax) =>
        `Nos conseillers vous répondent du lundi au vendredi de ${heureMin} à ${heureMax} (hors jours fériés).`,
    contactInformationsParagraphe5:
        "Pour moins d'attente, privilégiez les heures creuses : 8h30 à 9h00 et à partir de 17h00.",

    contactDemandeInformationTitle: "Poser une question",
    contactDemandeInformationTextInfo:
        "Si vous n'avez pas trouvé la réponse à votre question dans la « Foire aux questions », vous pouvez nous envoyer une demande d'informations. Pour garantir une bonne gestion de votre demande, veuillez remplir les champs ci-dessous. Nos conseillers vous répondront sous 24h (jours ouvrés).",

    contactDemandeRappelTitle: "Etre appelé(e)",
    contactDemandeRappelTextInfo:
        "Si vous n'avez pas trouvé la réponse à votre question dans la « Foire aux questions », vous pouvez nous envoyer une demande de rappel. Pour garantir une bonne gestion de votre demande, veuillez remplir les champs ci-dessous. Nos conseillers vous rappelleront en respectant le créneau horaire sélectionné.",

    contactDemandeDateLabel: "Quand souhaitez vous être appelé(e) ?",
    contactDemandeHoraireLabel: "Veuillez sélectionner un créneau horaire ?",
    contactDemandeHoraireMatinLabel: "Entre 9h et 12h30",
    contactDemandeHoraireApresMidiLabel: "Entre 13h30 et 18h00",
    contactDemandeObjetLabel: "Quel est l'objet de votre demande ?",
    contactDemandeDescriptionLabel: "Description",

    contactDemandeSendButton: "Envoyer",

    contactDemandeSuccessMessage: "Votre demande a bien été envoyée",

    contactChangeTabModalTitle: "Annuler",
    contactChangeTabModalParagraph: "Si vous quittez cette page, toutes vos modifications seront perdues.",
    contactChangeTabModalParagraph2: "Etes-vous sûr de vouloir quitter ?",
    contactChangeTabModalReturnButton: "Non, je reste sur cette page",
    contactChangeTabModalConfirmButton: "Oui, je quitte sans enregistrer",

    // COMMANDE UNABLED SAVE MODAL
    commandeUnabledSaveModalTitle: "Enregistrement impossible",
    demandeServiceUnabledSaveModalFirstParagraph:
        "Vous ne souhaitez pas faire appel à un prestataire de notre réseau. Vous ne pouvez pas passer à l’étape suivante.",
    demandeServiceUnabledSaveModalSecondParagraph: "Veuillez modifier la commande.",
    demandeServiceUnabledSaveModalConfirmLabel: "Modifier",

    // RGPD
    rgpdTitle: "Protection des données à caractère personnel",
    rgpdParagraphe1:
        "Les données à caractère personnel recueillies font l'objet d'un traitement dont le responsable est votre financeur conformément à la réglementation relative à la protection des données à caractère personnel.",
    rgpdParagraphe2: "Elles sont traitées pour utiliser l'espace bénéficiaire de Domiserve.",
    rgpdParagraphe3:
        "Les données sont traitées en vertu de l'exécution du contrat visant à l'utilisation de l'espace bénéficiaire Domiserve. Ces données seront conservées pendant 5 ans à compter la date de votre demande.",
    rgpdParagraphe4:
        "Les données à caractère personnel collectées sont obligatoires afin de bénéficier des services accessibles sur l'espace bénéficiaire Domiserve (effectuer une demande, suivre une demande, consulter l'historique des demandes). A défaut, vous ne pourrez pas utiliser les services disponibles sur l'espace bénéficiaire Domiserve.",
    rgpdParagraphe5:
        "Dans certains cas, les données concernant la création de votre compte (civilité, nom, prénom, date de naissance, email, adresse) peuvent avoir été collectées auprès de votre financeur.",
    rgpdParagraphe6:
        "Elles sont destinées à Domiserve et pourront être communiquées à ses sous-traitants ou partenaires pour les traitements et finalités cités ci-avant. Elles pourront également être communiquées à toute autorité administrative ou judiciaire habilitée ou plus généralement à tout tiers autorisé, pour satisfaire à ses obligations légales ou réglementaires.",
    rgpdParagraphe7:
        "Vous pouvez à tout moment dans les conditions prévues par la loi, accéder à vos données à caractère personnel, vous opposer pour un motif tenant à votre situation particulière à leur traitement ou vous opposer sans motif au traitement de vos données à caractère personnel dans le cadre des opérations de prospection commerciale, faire rectifier vos données à caractère personnel, demander leur effacement sauf si elles sont nécessaires à l'exécution des services ou au respect d'obligations légales ou pour permettre à Domiserve de constater ou d'exercer ses droits, la limitation de leur traitement, ou leur portabilité pour les données que vous avez fournies et qui sont nécessaires au contrat ou au traitement auquel vous avez consenti. ",
    rgpdParagraphe7b:
        "Vous pouvez à tout moment retirer votre consentement lorsque celui-ci a été préalablement donné. Vous pouvez aussi donner des instructions relatives à la conservation, à l'effacement et à la communication de vos données après votre décès. Ces droits peuvent être exercés, en précisant vos nom, prénom, adresse postale et en joignant tous éléments nous permettant de nous assurer de votre identité, en vous adressant, soit par courrier à Domiserve - Exercice de droits RGPD - 106 avenue Marx Dormoy - 92120 Montrouge soit par mail à ",
    rgpdParagraphe8:
        "Domiserve a désigné un Délégué à la protection des données dont les coordonnées sont : Délégué à la Protection des Données de La Banque Postale - 115, rue de Sèvres - 75275 Paris Cedex 06.",
    rgpdParagraphe9:
        "En cas de difficulté en lien avec la gestion de vos données à caractère personnel, vous avez le droit d'introduire une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés (CNIL).",

    // ERRORS
    technicalError: "Une erreur technique est survenue",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
