/**
 * The version key.
 */
export const VersionKeys = {
    V0_0_0: "V0.0.0",
    V1_0_0: "V1.0.0",
    V1_1_0: "V1.1.0",
    V2_0_0: "V2.0.0",
    V2_1_0: "V2.1.0",
    V2_2_0: "V2.2.0",
    V2_3_0: "V2.3.0",
    V2_4_0: "V2.4.0",
    V2_5_0: "V2.5.0",
    V2_6_0: "V2.6.0",
    V2_6_1: "V2.6.1",
    V2_7_0: "V2.7.0",
    V2_8_0: "V2.8.0",
    V2_9_0: "V2.9.0",
    V2_10_0: "V2.10.0",
    V2_11_0: "V2.11.0",
    V2_12_0: "V2.12.0",
    V2_13_0: "V2.13.0",
    V2_14_0: "V2.14.0",
    V2_15_0: "V2.15.0",
    V2_16_0: "V2.16.0",
    V2_17_0: "V2.17.0",
    V2_18_0: "V2.18.0",
    V2_19_0: "V2.19.0",
    V2_20_0: "V2.20.0",
    V2_21_0: "V2.21.0",
    V2_22_0: "V2.22.0",
    V2_23_0: "V2.23.0",
    V2_24_0: "V2.24.0",
    V2_25_0: "V2.25.0",
    V2_26_0: "V2.26.0",
    V2_26_1: "V2.26.1",
    V2_27_0: "V2.27.0",
    V2_28_0: "V2.28.0",
    V2_29_0: "V2.29.0",
    V2_30_0: "V2.30.0",
    V2_31_0: "V2.31.0",
    V2_32_0: "V2.32.0",
    V2_33_0: "V2.33.0",
    V2_34_0: "V2.34.0",
    V2_35_0: "V2.35.0",
    V2_36_0: "V2.36.0",
    V2_37_0: "V2.37.0",
    V2_38_0: "V2.38.0",
    V2_39_0: "V2.39.0",
    V2_40_0: "V2.40.0",
    V2_40_1: "V2.40.1",
    V2_41_0: "V2.41.0",
    V2_42_0: "V2_42_0",
    V2_43_0: "V2_43_0",
    V2_44_0: "V2.44.0",
    V2_45_0: "V2.45.0",
    V2_46_0: "V2.46.0",
    V2_47_0: "V2.47.0",
    V2_48_0: "V2.48.0",
    V2_49_0: "V2.49.0",
    V2_50_0: "V2.50.0",
    V2_51_0: "V2.51.0",
    V2_52_0: "V2.52.0",
    V2_53_0: "V2.53.0",
    V2_54_0: "V2.54.0",
    V2_55_0: "V2.55.0",
    V2_56_0: "V2.56.0",
    V2_57_0: "V2.57.0",
}

/**
 * The version structure.
 */
export const Versions = {
    [VersionKeys.V0_0_0]: {
        emptyState: false,
        next: VersionKeys.V1_0_0,
    },
    [VersionKeys.V1_0_0]: {
        emptyState: false,
        next: VersionKeys.V1_1_0,
    },
    [VersionKeys.V1_1_0]: {
        emptyState: false,
        next: VersionKeys.V2_0_0,
    },
    [VersionKeys.V2_0_0]: {
        emptyState: true,
        next: VersionKeys.V2_1_0,
    },
    [VersionKeys.V2_1_0]: {
        emptyState: true,
        next: VersionKeys.V2_2_0,
    },
    [VersionKeys.V2_2_0]: {
        emptyState: false,
        next: VersionKeys.V2_3_0,
    },
    [VersionKeys.V2_3_0]: {
        emptyState: false,
        next: VersionKeys.V2_4_0,
    },
    [VersionKeys.V2_4_0]: {
        emptyState: true,
        next: VersionKeys.V2_5_0,
    },
    [VersionKeys.V2_5_0]: {
        emptyState: true,
        next: VersionKeys.V2_6_0,
    },
    [VersionKeys.V2_6_0]: {
        emptyState: false,
        next: VersionKeys.V2_6_1,
    },
    [VersionKeys.V2_6_1]: {
        emptyState: false,
        next: VersionKeys.V2_7_0,
    },
    [VersionKeys.V2_7_0]: {
        emptyState: false,
        next: VersionKeys.V2_8_0,
    },
    [VersionKeys.V2_8_0]: {
        emptyState: true,
        next: VersionKeys.V2_9_0,
    },
    [VersionKeys.V2_9_0]: {
        emptyState: true,
        next: VersionKeys.V2_10_0,
    },
    [VersionKeys.V2_10_0]: {
        emptyState: true,
        next: VersionKeys.V2_11_0,
    },
    [VersionKeys.V2_11_0]: {
        emptyState: true,
        next: VersionKeys.V2_12_0,
    },
    [VersionKeys.V2_12_0]: {
        emptyState: true,
        next: VersionKeys.V2_13_0,
    },
    [VersionKeys.V2_13_0]: {
        emptyState: true,
        next: VersionKeys.V2_14_0,
    },
    [VersionKeys.V2_14_0]: {
        emptyState: true,
        next: VersionKeys.V2_15_0,
    },
    [VersionKeys.V2_15_0]: {
        emptyState: true,
        next: VersionKeys.V2_16_0,
    },
    [VersionKeys.V2_16_0]: {
        emptyState: true,
        next: VersionKeys.V2_17_0,
    },
    [VersionKeys.V2_17_0]: {
        emptyState: true,
        next: VersionKeys.V2_18_0,
    },
    [VersionKeys.V2_18_0]: {
        emptyState: true,
        next: VersionKeys.V2_19_0,
    },
    [VersionKeys.V2_19_0]: {
        emptyState: true,
        next: VersionKeys.V2_20_0,
    },
    [VersionKeys.V2_20_0]: {
        emptyState: true,
        next: VersionKeys.V2_21_0,
    },
    [VersionKeys.V2_21_0]: {
        emptyState: true,
        next: VersionKeys.V2_22_0,
    },
    [VersionKeys.V2_22_0]: {
        emptyState: true,
        next: VersionKeys.V2_23_0,
    },
    [VersionKeys.V2_23_0]: {
        emptyState: true,
        next: VersionKeys.V2_24_0,
    },
    [VersionKeys.V2_24_0]: {
        emptyState: true,
        next: VersionKeys.V2_25_0,
    },
    [VersionKeys.V2_25_0]: {
        emptyState: true,
        next: VersionKeys.V2_26_0,
    },
    [VersionKeys.V2_26_0]: {
        emptyState: true,
        next: VersionKeys.V2_26_1,
    },
    [VersionKeys.V2_26_1]: {
        emptyState: true,
        next: VersionKeys.V2_27_0,
    },
    [VersionKeys.V2_27_0]: {
        emptyState: true,
        next: VersionKeys.V2_28_0,
    },
    [VersionKeys.V2_28_0]: {
        emptyState: true,
        next: VersionKeys.V2_29_0,
    },
    [VersionKeys.V2_29_0]: {
        emptyState: true,
        next: VersionKeys.V2_30_0,
    },
    [VersionKeys.V2_30_0]: {
        emptyState: true,
        next: VersionKeys.V2_31_0,
    },
    [VersionKeys.V2_31_0]: {
        emptyState: true,
        next: VersionKeys.V2_32_0,
    },
    [VersionKeys.V2_32_0]: {
        emptyState: true,
        next: VersionKeys.V2_33_0,
    },
    [VersionKeys.V2_33_0]: {
        emptyState: true,
        next: VersionKeys.V2_34_0,
    },
    [VersionKeys.V2_34_0]: {
        emptyState: true,
        next: VersionKeys.V2_35_0,
    },
    [VersionKeys.V2_35_0]: {
        emptyState: true,
        next: VersionKeys.V2_36_0,
    },
    [VersionKeys.V2_36_0]: {
        emptyState: true,
        next: VersionKeys.V2_37_0,
    },
    [VersionKeys.V2_37_0]: {
        emptyState: true,
        next: VersionKeys.V2_38_0,
    },
    [VersionKeys.V2_38_0]: {
        emptyState: true,
        next: VersionKeys.V2_39_0,
    },
    [VersionKeys.V2_39_0]: {
        emptyState: true,
        next: VersionKeys.V2_40_0,
    },
    [VersionKeys.V2_40_0]: {
        emptyState: true,
        next: VersionKeys.V2_40_1,
    },
    [VersionKeys.V2_40_1]: {
        emptyState: true,
        next: VersionKeys.V2_41_0,
    },
    [VersionKeys.V2_41_0]: {
        emptyState: true,
        next: VersionKeys.V2_42_0,
    },
    [VersionKeys.V2_42_0]: {
        emptyState: true,
        next: VersionKeys.V2_43_0,
    },
    [VersionKeys.V2_43_0]: {
        emptyState: true,
        next: VersionKeys.V2_44_0,
    },
    [VersionKeys.V2_44_0]: {
        emptyState: true,
        next: VersionKeys.V2_45_0,
    },
    [VersionKeys.V2_45_0]: {
        emptyState: true,
        next: VersionKeys.V2_46_0,
    },
    [VersionKeys.V2_46_0]: {
        emptyState: true,
        next: VersionKeys.V2_47_0,
    },
    [VersionKeys.V2_47_0]: {
        emptyState: true,
        next: VersionKeys.V2_48_0,
    },
    [VersionKeys.V2_48_0]: {
        emptyState: true,
        next: VersionKeys.V2_49_0,
    },
    [VersionKeys.V2_49_0]: {
        emptyState: true,
        next: VersionKeys.V2_50_0,
    },
    [VersionKeys.V2_50_0]: {
        emptyState: true,
        next: VersionKeys.V2_51_0,
    },
    [VersionKeys.V2_51_0]: {
        emptyState: true,
        next: VersionKeys.V2_52_0,
    },
    [VersionKeys.V2_52_0]: {
        emptyState: true,
        next: VersionKeys.V2_53_0,
    },
    [VersionKeys.V2_53_0]: {
        emptyState: true,
        next: VersionKeys.V2_54_0,
    },
    [VersionKeys.V2_54_0]: {
        emptyState: true,
        next: VersionKeys.V2_55_0,
    },
    [VersionKeys.V2_55_0]: {
        emptyState: true,
        next: VersionKeys.V2_56_0,
    },
    [VersionKeys.V2_56_0]: {
        emptyState: true,
        next: VersionKeys.V2_57_0,
    },
    [VersionKeys.V2_57_0]: {
        emptyState: true,
    },
}
